@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.paragraphs-item-hp-who-we-work-with {
  background: #fff;
  @include clearfix;

  padding: 30px 0 26px;
  @include breakpoint($desktop) {
    padding: 60px 0 60px;
  }

  p {
    font-size: 19px;
  }
}

//.field-name-field-client-logos {
//  padding-top: 40px - 22; // 22 is the bottom margin of a p tag
//  //text-align: center;
//
//  @include breakpoint($mobile) {
//    column-count: 2;
//    column-gap: 14px;
//  }
//  column-count: 4;
//  column-gap: 24px;
//}
//
//.field-name-field-client-logos img {
//  margin-bottom: 14px;
//  @include breakpoint($desktop) {
//    margin-bottom: 24px;
//  }
//}
